<template>
  <div class="container" v-if="userInfo != []">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'7'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>闲赚</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('0')">
          <span>申请列表</span>
        </div>
        <div class="left-list-item" @click="leftClick('1')">
          <span>闲赚伙伴</span>
        </div>
        <div class="left-list-item" @click="leftClick('2')">
          <span>闲赚订单</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>申请详情</span>
        </div>
        <div class="progress"></div>
        <div class="title mt-20">
          <span>用户信息</span>
        </div>
        <div class="user-table">
          <el-table :data="userInfo" style="width: 100%" border>
            <el-table-column
              prop="user.id"
              label="用户ID"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="image"
              label="用户头像"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <img
                  style="width: 60%"
                  :src="scope.row.user.avatarUrl"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="user.nickName"
              label="用户昵称"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="type" label="类型" align="center">
            </el-table-column>
          </el-table>
        </div>
        <div class="title mt-20" v-if="userInfo[0].type == '个人'">
          <span>个人信息</span>
        </div>
        <div class="shop-table" v-if="userInfo[0].type == '个人'">
          <el-table :data="userInfo" style="width: 100%" border>
            <el-table-column
              prop="account"
              label="姓名"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="id_card"
              label="身份证号码"
              width="300"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="link_number" label="手机号码" align="center">
            </el-table-column>
          </el-table>
        </div>
        <div class="title mt-20" v-if="userInfo[0].type == '公司'">
          <span>企业信息</span>
        </div>
        <div class="enterprise" v-if="userInfo[0].type == '公司'">
          <el-table :data="userInfo" style="width: 100%" border>
            <el-table-column
              prop="company_name"
              label="企业名称"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="image"
              label="营业执照"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <img
                  style="width: 80%"
                  :src="$store.state.imaUrl + scope.row.company_image"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="class"
              label="办公地址"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.city_id }},
                {{ scope.row.province_id }}
              </template>
            </el-table-column>

            <el-table-column
              prop="account"
              label="对接人姓名"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="id_card"
              label="对接人身份证号"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="link_number"
              label="对接人手机号码"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="title mt-20">
          <span>资质审核</span>
        </div>
        <div class="mt-20 physical-examination" v-if="userInfo[0].status == 0">
          <el-radio-group v-model="material" style="margin-right: 20px">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
          <el-select
            size="mini"
            v-model="materialReject"
            placeholder="驳回原因"
            v-if="material == 2"
            lcass="ml-40"
          >
            <el-option
              v-for="item in remarkList"
              :key="item.remark"
              :label="item.remark"
              :value="item.remark"
            >
            </el-option>
          </el-select>
          <div class="mt-20">
            <el-button type="primary" @click="materialClick">确定</el-button>
          </div>
        </div>
        <div class="mt-20 physical-examination" v-else>
          <div class="mt-20">
            <span v-if="userInfo[0].status == 1">已通过</span
            ><span v-if="userInfo[0].status == 2">已驳回</span>
          </div>
          <div class="mt-20">
            <span v-if="userInfo[0].status == 2"
              >驳回原因：{{ userInfo[0].remark }}</span
            >
          </div>
          <div class="mt-20">
            <span>审核时间：{{ timestampToTime(userInfo[0].check_time) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../components/leftList.vue";
import topHeader from "../../components/header.vue";
export default {
  components: {
    leftList,
    topHeader,
  },
  inject: ["reload"],
  data() {
    return {
      remarkList: [],
      flawIndex: 0,
      flawList: [
        {
          inputValue: "",
          imageUrl: "",
        },
      ],
      materialReject: null,
      imgReject: "",
      material: 1,
      consignment: "",
      sold: "",
      radio: "1",
      userInfo: [],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      isCollapse: false,
      starttime: "",
      endtime: "",
      shopiing: "",
      state: "",
      inpuValue: "",
      region: "",
      pickerOptions: {
        //控制时间范围
        disabledDate(time) {
          return time.getTime() > Date.now() - 24 * 60 * 1000;
        },
      },
    };
  },
  created() {
    this.getList();
    this.getRemarklist();
  },
  methods: {
    timestampToTime(cjsj) {
      var date = new Date(cjsj * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    getRemarklist() {
      this.$get("get/remark", { num: 999 }).then((res) => {
        this.remarkList = res.data.data.data;
      });
    },
    getList() {
      // console.log(this.$query.id);
      this.$get("get/apply_dealer_backend", {
        user_id: this.$route.query.id,
      }).then((res) => {
        this.tableData = res.data.data.data;
        this.userInfo = [];
        this.userInfo.push(res.data.data.data[0]);
        // console.log(res.data.data.data[0].user);
      });
    },
    materialClick() {
      if (this.material == 2) {
        if (this.materialReject == null) {
          this.$notify({
            title: "提示",
            message: "请选择驳回原因",
            type: "warning",
          });
          return false;
        }
      }
      this.$post("check/dealer_apply", {
        id: this.userInfo[0].id,
        status: this.material,
        remark: this.materialReject,
      }).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "提示",
            message: "操作成功！",
            type: "success",
          });
          this.getList();
        } else {
          this.$notify.error({
            title: "提示",
            message: res.data.message,
          });
        }
      });
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/idleearning") {
        this.$router.push("/idleearning");
      } else if (index == 1 && this.$route.path != "/partner") {
        this.$router.push("/partner");
      } else if (index == 2 && this.$route.path != "/idleearningorder") {
        this.$router.push("/idleearningorder");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang="scss">
.reason {
  width: 500px;
  display: inline-block;
  .el-input {
    width: 200px;
  }
}
.operation {
  display: inline-block;
  vertical-align: top;
}
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .enterprise {
    display: inline-block;
    width: 1200px;
  }
  .physical-examination {
    padding: 0 100px;
  }
  .shopimg-list {
    margin-top: 20px;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .user-table {
    display: inline-block;
    width: 600px;
    padding-top: 20px;
  }
  .shop-table {
    display: inline-block;
    width: 800px;
    padding-top: 20px;
  }
  .img-compress {
    text-align: right;
    span,
    i {
      cursor: pointer;
    }
  }
  .progress {
    margin-top: 50px;
  }
  .date-search {
    margin-top: 30px;
    .el-button {
      margin-left: 40px;
      padding: 12px 55px;
    }
    .el-input {
      width: 200px;
    }
    .left,
    .right {
      display: inline-block;
    }
    .right {
      width: 1000px;
      vertical-align: top;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
